import React from "react";
import { useLocation } from "react-router-dom";
import "./Printbillstyle.css";

export default function Print() {
  const location = useLocation();
  const { tabledetails, apidata } = location.state;
  const print = () => {
    window.print();
  };
  const Tablenumber = apidata.tableDetails.TableNumber;
  const orderedtime = apidata.tableProducts[0].OrderedTime;

  return (
    <>
      {" "}
      <div className="printcontainer">
        <p>-----------------------</p>
        <div className="headingreciept">
          <p>
            <b>{orderedtime} </b>
          </p>
        </div>
        <div className="orderby">
          <p>
            {" "}
            <b>Ordered By GLORIA</b>
          </p>
        </div>
        <div className="tableNumber">
          <p>
            {" "}
            <b> TABLE - {Tablenumber} </b>
          </p>
        </div>

        {tabledetails.map((value) => {
          return (
            <div className="productList">
              <div>
                <p>
                  {value.ProductQty}*{value.Description}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="printBtn">
        <button class="hide-on-print" onClick={print}>
          Print
        </button>
      </div>
    </>
  );
}
