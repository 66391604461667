import React, { useEffect, useState } from "react";
import "../../../src/style/style.css";
import SORDIZ from "../../secret";

const Menutable = ({ add }) => {
  const { BASE_URL } = SORDIZ;
  const [menuData, setMenuData] = useState([]);
  const [headerlist, setHeaderlist] = useState([]);
  const [implist, setImplist] = useState([]);
  const [displaylist, setDisplaylist] = useState([]);
  const [hide, sethide] = useState(false);
  const [top, setTop] = useState([]);
  const [headhide, setHeadhide] = useState(false);
  const [currentsearch, setCurrentsearch] = useState("");
  const [searchlist, setSearchList] = useState([]);
  const [filterdata, setFilterData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      fetch(
        `${BASE_URL}/GetProducts?BusinessId=` +
          window.sessionStorage.getItem("businessId"),
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token"),
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setMenuData(data);
          // console.log("data",data);
          setHeaderlist(data.pGroupList);
          setImplist(data.productsList);
          setSearchList(data.productsList);
        });
    }
    fetchData();
  }, []);

  let favouritearr = [];
  let favouritearr2 = [];

  for (let i = 0; i < implist.length; i++) {
    if (implist[i].productGroupID === 0) {
      favouritearr.push(implist[i].description);
      favouritearr2.push(implist[i]);
    }
  }

  let pgarr = [];
  for (let i = 0; i < headerlist.length; i++) {
    if (headerlist[i].parentGroupID === 0) {
      pgarr.push(headerlist[i]);
    }
  }

  let displayarr = [];
  const Ingroup = (item) => {
    sethide(true);
    setHeadhide(true);
    for (let i = 0; i < implist.length; i++) {
      if (implist[i].productGroupID === item.productGroupID) {
        displayarr.push(implist[i]);
        setDisplaylist(displayarr);
      }
    }
    pgarr.splice(0, pgarr.length);
    for (let i = 0; i < headerlist.length; i++) {
      if (headerlist[i].parentGroupID === item.productGroupID) {
        pgarr.push(headerlist[i]);
        setTop(pgarr);
      }
    }
  };

  const handleChange = (searchValue) => {
    setCurrentsearch(searchValue);
    if (currentsearch !== "") {
      const filterDATA = searchlist.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(currentsearch.toLowerCase());
      });
      setFilterData(filterDATA);
    } else {
      setFilterData(searchlist);
    }
  };

  return (
    <div className="container mt-3" style={{ background: "beige" }}>
      <div>
        <h5 className="text-center mb-3">Menu Items</h5>
      </div>
      <div className="searchContainer">
        <input
          className="searchInput"
          placeholder="Search"
          onChange={(e) => handleChange(e.target.value)}
          type="text"
        ></input>
      </div>

      {currentsearch.length > 1
        ? filterdata.map((item, index) => {
            return (
              <button
                className="btn btn-outline-dark border my-2 wrap mx-2"
                onClick={() => add(item)}
              >
                {item.description}
              </button>
            );
          })
        : ""}
      <div>
        <div className="border ">
          <ul
            className="list-group list-group-horizontal  rounded-0 d-flex flex-wrap justify-content-around my-1"
            style={{ background: "beige", flexDirection: "row-reverse" }}
          >
            {headhide ? (
              <>
                {top.map((item, index) => {
                  return (
                    <button
                      key={index}
                      type="button"
                      onClick={() => {
                        Ingroup(item);
                      }}
                      className="btn my-3 "
                      data-toggle="button"
                      aria-pressed="false"
                      style={{
                        width: "110px",
                        border: "3px solid black",
                        background: "rgb(88, 30, 30)",
                        color: "white",
                      }}
                    >
                      {item.groupname}
                    </button>
                  );
                })}
                {hide ? (
                  <button
                    className="btn my-3 "
                    style={{
                      width: "100px",
                      border: "3px solid black",
                      background: "rgb(88, 30, 30)",
                      color: "white",
                      marginLeft: "7px",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      sethide(false);
                      setHeadhide(false);
                    }}
                  >
                    Main
                  </button>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                {pgarr.map((item, index) => {
                  return (
                    <button
                      key={index}
                      type="button"
                      onClick={() => {
                        Ingroup(item);
                      }}
                      onChange
                      className="btn my-3 "
                      data-toggle="button"
                      aria-pressed="false"
                      style={{
                        width: "110px",
                        border: "3px solid black",
                        background: "rgb(88, 30, 30)",
                        color: "white",
                      }}
                    >
                      {item.groupname}
                    </button>
                  );
                })}
              </>
            )}
          </ul>
          <div>
            {!hide
              ? favouritearr2.map((item, index) => {
                  return (
                    <button
                      className="btn btn-outline-dark border my-2 wrap mx-2"
                      onClick={() => add(item)}
                    >
                      {item.description}
                    </button>
                  );
                })
              : ""}
          </div>
        </div>

        {hide ? (
          displaylist.map((item, index) => {
            return (
              <button
                key={index}
                onClick={() => add(item)}
                className="btn btn-outline-dark border my-2 wrap mx-2"
              >
                {item.description}
              </button>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Menutable;
