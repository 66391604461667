import React, { useEffect, useState } from "react";
import Tables from "../tables";
import Header from "../Header/header";
import Footer from "../Footer/footer";
import { Link } from "react-router-dom";
import SORDIZ from "../../secret";
const Tablelist = () => {
  const { BASE_URL } = SORDIZ;
  const [tables, updateTables] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    setInterval(() => {
      async function fetchData() {
        fetch(
          `${BASE_URL}/GetTableBySection?UserID=208&sectionId=100&BusinessId=` +
            window.sessionStorage.getItem("businessId"),
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token"),
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("test",data);
            updateTables(data.tablesList);
            // window.sessionStorage.setItem("orderid",data.tablesList)
            setLoader(false);
          });
      }
      fetchData();
    }, 5000);
  }, []);


  return (
    <>
      {loader ? (
        <div className="loader-wrapper" id="loaderbox">
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      <div>
        <div>
          <Header />
        </div>
        <div
          style={{
            position: "relative",
            top: "-42px",
            display: "inline-block",
          }}
        >
          <Link className="textLink" to="/Homepage">
            <button
              className="btn"
              style={{
                width: "59px",
                background: "rgb(171 171 171)",
                color: "black",
                border: "1px solid white",
              }}
            >
              {" "}
              Back
            </button>
          </Link>
        </div>
        <div style={{backgroundColor:"rgb(235, 235, 222)"}}>
          <Tables tables={tables ? tables : []} />
          <Footer  />
        </div>
        {/* <div>
        </div>{" "} */}
      </div>
    </>
  );
};

export default Tablelist;
