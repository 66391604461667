import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./tableDetails.css";
import SplitBill from "../SplitBill/splitBill";
import Payment from "../Payment";
import CheckModal from "./CheckModal";

const Thetable = ({
  tabledetails,
  add,
  remove,
  tableHeaders,
  updateTableproducts,
  orderguid,
  setOrderGUID,
}) => {
  const [splitBill, SetSplitBill] = useState(false);
  const [bufferitems, setBufferItems] = useState("");
  const [customerid, setCustomerId] = useState("");
  const [issuccess, setIsSuccess] = useState("");
  const [mobilenumber, setMobileNumber] = useState("");
  const [lastorderno, setLastOrderNo] = useState("");
  const [payasyougo, setPayAsYouGo] = useState("");
  const [adcount, setAdCount] = useState("");
  const [currentstatus, setCurrentStatus] = useState("");
  const [currenttotal, setCurrentTotal] = useState("");
  const [jncount, setJnCount] = useState("");
  const [kdcount, setKdCount] = useState("");
  const [occupiedtime, setOccupiedTime] = useState("");
  const [tableorderguid, setTableOrderGUID] = useState("null");
  const [parrenttablenumber, setParentTableNumber] = useState("");
  const [paxcount, setPaxCount] = useState("");
  const [restaurantid, setRestaurantId] = useState("1");
  const [tableid, setTableID] = useState("");
  const [tablenumber, setTableNumber] = useState("");
  const [uniquecode, setUniqueCode] = useState("");
  const [userid, setUserId] = useState("208");
  const [userlevel, setUserLevel] = useState("");
  const [userlevelid, setUserLevelID] = useState("6");
  const [vedrsioncode, setVersionCode] = useState("0");
  const [versionname, setVersionName] = useState("null");
  const [amt, setAmt] = useState([]);
  const [ison, setIson] = useState(false);
  const [currentqty, setCurrentqty] = useState("");
  const [updateqty, setUpdateqty] = useState("");
  const [loader, setLoader] = useState(false);
  const [popshow, setPopshow] = useState(false);
  const [catcherror, setCatchError] = useState(false);
  const [error, setError] = useState("");
  const [color, setColor] = useState("");
  const [apidata, setApidata] = useState([]);
  const [wdata, setWdata] = useState([]);
  const [tabdata, setTabdata] = useState([]);
  const [tabdataq, setTabdataq] = useState([]);

  const roundUptoTwoDecimalPlaces = (number) => {
    return Math.round(number * 100) / 100;
  };

  var count = 0;

  // useEffect(() => {
  //   async function fetchData() {
  //     const path = window.location.pathname.split("/");
  //     fetch(
  //       `http://192.168.1.6/SordizApi/GetTableOrder?orderid=${window.sessionStorage.getItem(
  //         "orderid"
  //       )}&UserId=208`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: "Bearer " + window.sessionStorage.getItem("token"),
  //         },
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setApidata(data);
  //         setBufferItems(data.BuffetItems);
  //         setCustomerId(data.CustomerId);
  //         setIsSuccess(data.IsSuccess);
  //         setMobileNumber(data.MobileNumber);
  //         setLastOrderNo(data.LastOrderNo);
  //         setPayAsYouGo(data.PayAsYouGo);
  //         setAdCount(data.tableDetails.AdCount);
  //         setCurrentStatus(data.tableDetails.CurrentStatus);
  //         setCurrentTotal(data.tableDetails.CurrentTotal);
  //         setJnCount(data.tableDetails.JnCount);
  //         setKdCount(data.tableDetails.KdCount);
  //         setOccupiedTime(data.tableDetails.OccupiedTime);
  //         setTableOrderGUID("TableOrderGUID", "null");
  //         setParentTableNumber(data.tableDetails.ParentTableNumber);
  //         setPaxCount(data.tableDetails.PaxCount);
  //         setTableID(data.tableDetails.TableID);
  //         setTableNumber(data.tableDetails.TableNumber);
  //         setUniqueCode(data.tableDetails.UniqueCode);
  //         setUserLevel(data.tableUser.UserLevel);
  //       });
  //   }
  //   fetchData();
  // }, []);

  async function onSubmt() {
    setLoader(true);
    const url = "https://api.sordiz.com/SubmitOrder";
    let data = {
      BuffetItems: [],
      CustomerId: 0,
      businessId: "87D58FDD-8A4A-448A-8DFC-4B10289F4501",
      IsSuccess: false,
      MobileNumber: 0,
      LastOrderNo: 1,
      PayAsYouGo: false,
      OrderGUID: orderguid,
      Type: "N",
      UserName: "Test6",
      UserType: "Staff",
      DeviceType: "WEB",
      tableCustomer: {
        AdCnt: 4,
        CoverBuffetCnt: 0,
        CustomerID: 0,
        CustomerPoints: 0,
        DOB: null,
        DepositAmount: 0.0,
        DepositPaid: false,
        FullName: null,
        HighChair: 0,
        IncomingTime: null,
        JnCnt: 0,
        KdCnt: 0,
        Mobile: null,
        Name: null,
        NoOfGuests: 0,
        Prams: 0,
        PrevOrdersDate: null,
        Processed: false,
        ProductID: 0,
        ReservationID: 0,
        TableID: 0,
        TotalSeats: 0,
        Type: "E",
        UniqueCode: "",
        UserID: 0,
        WaitingTime: 0,
        WheelChair: 0,
        isBuffet: false,
        prevCust: false,
      },
      joinTables: [],
      tableDetails: {
        AdCount: 4,
        CurrentStatus: 0,
        CurrentTotal: 0,
        JnCount: 0,
        KdCount: 0,
        OccupiedTime: null,
        OrderGUID: orderguid,
        ParentTableNumber: null,
        PaxCount: 4,
        RestaurantId: 3,
        RestaurantName: "Bolton",
        TableID: 22,
        TableNumber: "82",
        UniqueCode: 0,
      },
      tableProducts: wdata,

      tableUser: {
        UserID: 208,
        UserLevel: 0,
        UserLevelID: 6,
        UserName: "Test6",
        UserPrinter: "Bar",
        VersionCode: 0,
        VersionName: null,
      },
    };
    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then(async (response) => {
        console.log(response);
        if (
          response.message ===
          null
        ) {
          setLoader(false);
          setOrderGUID(response.orderGUID);
          window.sessionStorage.setItem("orderid", response.orderGUID);
          setPopshow(true);
          setColor("#8e8e7c");
          setError(response.message);
        } else {
          setLoader(false);
          setPopshow(true);
          setColor("#C70100");
          setError(response.message);
        }
      })
      .catch((err) => {
        setCatchError(true);
        setColor("#C70100");
      });
  }

  // ***********************PLUS**********************
  
  const plus = (id) => {
    // // ***************** SAHIL******

    let newdata = tabledetails.map((item) => {
      // item.ProductQty = 1
      if (item.productID === id) {
        return { ...item, productQty: item.productQty + 1 };
      } else {
        return item;
      }
    });
    updateTableproducts(newdata);
    setWdata(newdata);
  };

  // //********************SAHIL */

  //   // setWdata((p) => {
  //   //   // return p.map((item) => {
  //   //   //   if(item.OrderPartID === id){
  //   //   //     return {
  //   //   //       ...item,
  //   //   //       tableProducts:{
  //   //   //         ...item.tableProducts,
  //   //   //         ProductQty: item.ProductQty + 1,
  //   //   //       },
  //   //   //     };
  //   //   //   } else {
  //   //   //     return item;
  //   //   //   }
  //   //   // });
  //   // });

  // ***********************PLUS*************************

  // const plus = (id) => {
  //   let newarr = []
  //   for(let i = 0; i<tabledetails.length; i++){

  //           if(tabledetails[i].ProductID === id ){
  //             tabledetails[i].ProductQty = tabledetails[i].ProductQty + 1

  //             newarr.push(tabledetails[i])
  //             console.log("newarr-1",tabledetails);
  //             console.log("newarr2",newarr);
  //             setWdata((p) =>[...p,...newarr])
  //           }

  //         }

  // }

  return (
    <>
      {loader ? (
        <div className="loader-wrapper" id="loaderbox">
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      {catcherror ? (
        <>
          <div className="modalmaindiv" id="welcomeDiv">
            <div className="modaldiv">
              <div className="cardbox" style={{ backgroundColor: color }}>
                <div className="erroriconbox">
                  <i className="fa fa-exclamation-triangle"></i>
                </div>

                <br />
                <p className="text-center text-white">{error}</p>
                <br />
                <div className="mainerrorclosebtn">
                  <div
                    className="errorclosebtn"
                    onClick={() => {
                      setPopshow(false);
                      window.location.reload();
                    }}
                  >
                    <p>OK</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container" style={{ background: "beige" }}>
            <div
              className="d-flex"
              style={{ display: "inline-block", marginTop: "15px" }}
            >
              <div className="w-100">
                <div>
                  <Link className="textLink" to="/home">
                    <button
                      className="btn"
                      style={{
                        width: "81px",
                        background: "rgb(88, 30, 30)",
                        color: "white",
                      }}
                    >
                      {" "}
                      Back
                    </button>
                  </Link>
                </div>
                <table
                  className="table table-bordered mt-2 table-striped"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr className="text-center">
                      {tableHeaders.map((item, index) => {
                        return (
                          <th key={index} scope="col">
                            {item}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    {tabledetails &&
                      tabledetails.map((item) => {
                        return (
                          <tr className="text-center">
                            <td>{item.description}</td>

                            <td></td>
                            <td>£{roundUptoTwoDecimalPlaces(item.price)}</td>
                            <td
                              style={{
                                maxWidth: "18px",
                                overflow: "hidden",
                                textOverflow: "clip",
                              }}
                            >
                              £
                              {roundUptoTwoDecimalPlaces(
                                item.price * (item.productQty || 1)
                              )}
                            </td>
                            <td
                              className="text-center"
                              style={{ cursor: "pointer", fontWeight: "bold" }}
                            >
                              <button
                                className="plusum"
                                onClick={(orderpartID) => {
                                  remove(item);
                                }}
                                // disabled={true}
                              >
                                -
                              </button>
                              <button
                                className="btn btn-outline border-0 font-weight-bold btn-sm p-0"
                                style={{
                                  cursor: "auto",
                                  marginLeft: "1px",
                                  marginRight: "1px",
                                }}
                              >
                                {item.productQty}
                              </button>{" "}
                              <button
                                className="plusum"
                                onClick={() => {
                                  // plus(item.productID);
                                  add(item)
                                }}
                              >
                                +
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div>
                  <div className="buttoncontainer">
                    {tabledetails.map((itm) => {
                      count = count + (itm.productQty || 1) * itm.price;
                    })}

                    <button className="btn btn-outline border px-4 disabled">
                      {" "}
                      Sub Total: £{roundUptoTwoDecimalPlaces(count)}{" "}
                    </button>

                    <div>
                      <span
                        className="btn  "
                        style={{
                          width: "82px",

                          color: "white",
                          padding: "2px",
                          background: "rgb(88, 30, 30)",
                        }}
                        onClick={() => {
                          SetSplitBill(true);
                        }}
                      >
                        {tabledetails && tabledetails.map((itm) => {})}

                        <SplitBill />
                      </span>
                    </div>

                    <div>
                      <button
                        className="btn  "
                        style={{
                          width: "82px",

                          color: "white",
                          padding: "2px",
                          background: "rgb(88, 30, 30)",
                        }}
                        onClick={onSubmt}
                      >
                        Submit
                      </button>
                    </div>
                    <div>
                      <span
                        className="btn  "
                        style={{
                          width: "82px",

                          color: "white",
                          padding: "2px",
                          background: "rgb(88, 30, 30)",
                        }}
                      >
                        <Payment />
                      </span>
                    </div>
                    <div>
                      <span
                        className="btn  "
                        style={{
                          width: "82px",

                          color: "white",
                          padding: "2px",
                          background: "rgb(88, 30, 30)",
                        }}
                      >
                        <CheckModal />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {popshow ? (
            <>
              <div className="modalmaindiv" id="welcomeDiv">
                <div className="modaldiv">
                  <div className="cardbox" style={{ backgroundColor: color }}>
                    {color === "#C70100" ? (
                      <>
                        <div className="erroriconbox">
                          <i className="fa fa-exclamation-triangle"></i>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="erroriconbox">
                          <i className="fa fa-badge-chech"></i>
                        </div>
                      </>
                    )}

                    <br />
                    <p className="text-center text-white">{error}</p>
                    <br />
                    <div className="mainerrorclosebtn">
                      {color === "#C70100" ? (
                        <>
                          <div
                            className="errorclosebtn"
                            id="close-pop"
                            onClick={() => {
                              setPopshow(false);
                            }}
                          >
                            <p>OK</p>
                          </div>
                        </>
                      ) : (
                        <div
                          className="errorclosebtn"
                          id="close-pop"
                          onClick={() => {
                            setPopshow(false);
                          }}
                        >
                          <p>OK</p>
                        </div>
                        // <Link
                        //   // to="/Print"
                        //   state={{
                        //     tabledetails: tabledetails,
                        //     apidata: apidata,
                        //   }}
                        //   style={{ textDecoration: "none" }}
                        // >
                        //   <div
                        //     className="errorclosebtn"
                        //     onClick={() => {
                        //       setPopshow(false);
                        //     }}
                        //   >
                        //     <p>OK</p>
                        //   </div>
                        // </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default Thetable;
