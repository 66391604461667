import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "../SplitBill/splitbill.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "3px solid grey",
    borderRadius: "12px",
    overflowX: "hidden",
    height: "100vh",
    width: "45vw",
    backgroundColor: "beige",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function SplitBill() {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [orderList, setOrderList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [totalAmt, setTotalAmt] = useState([]);
  const [sumt, setSumt] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  let sum = 0;
  let minus = 0;
  let tsum = 0;

  orderList.forEach((item) => {
    tsum += Number(item.Price);
  });

  minus = Number(tsum - sumt);

  useEffect(() => {
    async function fetchData() {
      const path = window.location.pathname.split("/");
      fetch(
        ` https://api.sordiz.com/v3/GetTableOrder?orderid=${path[2]}&UserId=208`
      )
        .then((response) => response.json())
        .then((data) =>
          setOrderList(
            data.tableProducts,
            orderList.map((item) => {
              return {
                select: false,
                id: item.id,
              };
            })
          )
        );
    }
    fetchData();
  }, []);

  function openModal() {
    setIsOpen(true);
  }
  // function afterOpenModal() {
  //   // references are now sync'd and can be accessed.
  //   subtitle.style.color = "#f00";
  // }

  function closeModal() {
    setIsOpen(false);
  }
  const splited = (orderPartId, pricingAmount, index) => {
    var a = parseFloat(setTotalAmt) + parseFloat(pricingAmount);
    var selectedItem = {
      OrderPartId: orderPartId,
      Price: pricingAmount,
    };

    let arr = [];

    let flag = null;
    for (let i = 0; i < selectedProduct.length; i++) {
      if (selectedProduct[i].OrderPartId !== selectedItem.OrderPartId) {
        arr.push(selectedProduct[i]);
      } else {
        flag = true;
      }
    }
    if (!flag) {
      // setSelectedProduct(p=>{return[...p,selectedItem]})
      arr.push(selectedItem);
    }
    console.log("new", arr);
    arr.forEach((item) => {
      sum += Number(item.Price);
    });

    setSumt(sum);
    console.log("newsum", sum);
    setSelectedProduct(arr);
    console.log(sumt);
  };

  return (
    <div>
      <button
        className="px-4 py-1"
        style={{
          backgroundColor: "transparent",
          height: "35px",
          border: "none",
          fontSize: "11px",
          color: "white",
          display: "contents",
        }}
        onClick={openModal}
      >
        Split Bill
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="nav justify-content-center">
          <h5
            className="text-black"
            ref={(_subtitle) => (subtitle = _subtitle)}
            style={{
              textAlign: "left",
              fontSize: "25px",
              textDecoration: "underline",
            }}
          >
            SPLIT BILL
          </h5>
        </div>
        <div>
          <div
            className="nav justify-content-start"
            style={{ position: "absolute" }}
          >
            <label>Table Number = </label>
            <input
              disabled
              style={{
                border: "none",
                width: "40px",
                paddingLeft: "9px",
                borderRadius: "9px",
                background: "#80808078",
              }}
              value={window.sessionStorage.getItem("tableno")}
            ></input>
          </div>
          <div className="nav justify-content-end">
            <label>Bill Amount= </label>
            <input
              disabled
              style={{
                border: "none",
                width: "50px",
                paddingLeft: "9px",
                borderRadius: "4px",
                background: "#80808078",
              }}
              value={tsum}
              readonly
            ></input>
          </div>
        </div>
        <div className="table-data">
          <table style={{ marginTop: "12px" }} className="table-bordered">
            <thead>
              <tr
                className="  py-2"
                style={{ backgroundColor: "rgb(168 168 159)", color: "black" }}
              >
                <th
                  scope="col"
                  style={{ width: "92%", padding: "10px 5px 10px 5px" }}
                >
                  Description
                </th>
                <th scope="col" style={{ padding: "10px 5px 10px 5px" }}>
                  Price
                </th>
              </tr>
            </thead>

            <tbody>
              {orderList &&
                orderList.map((item, index) => {
                  return (
                    <tr
                      key={item.id}
                      style={{
                        backgroundColor: selectedProduct.find(
                          (pro) => pro.OrderPartId === item.OrderPartID
                        )
                          ? "green"
                          : "unset",
                        color: selectedProduct.find(
                          (pro) => pro.OrderPartId === item.OrderPartID
                        )
                          ? "unset"
                          : "black",
                      }}
                      onClick={() =>
                        splited(item.OrderPartID, item.Price, index)
                      }
                    >
                      <th scope="row">{item.Description}</th>

                      <td>£{item.Price}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div
            style={{ flexDirection: "column" }}
            className="d-flex mt-5  justify-content-around"
          >
            <div className="inputContainer">
              <div>
                <label>Selected Items Total</label>

                <input
                  type="text"
                  className="form-control w-50"
                  style={{ backgroundColor: "white" }}
                  placeholder="£ 0.00"
                  readOnly
                  value={selectedProduct.length}
                />
              </div>
              <div>
                <label>Total Selected Price</label>
                <input
                  type="text"
                  style={{ backgroundColor: "white" }}
                  className="form-control w-50"
                  placeholder="£ 0.00"
                  readOnly
                  value={"£ " + sumt}
                />
              </div>
              <div>
                <label>Remaining Price</label>
                <input
                  type="text"
                  style={{ backgroundColor: "white" }}
                  className="form-control w-50"
                  placeholder="£ 0.00"
                  readOnly
                  value={"£ " + minus}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div>
                <button
                  style={{
                    width: "81px",
                    border: "3px solid",
                    backgroundColor: "rgb(88, 30, 30)",
                  }}
                  className="btn btn-dark my-3"
                  // onClick={()=>{ window.print();}}
                >
                  PAY
                </button>
              </div>
              <div>
                <button
                  style={{
                    width: "81px",
                    border: "3px solid",
                    backgroundColor: "rgb(88, 30, 30)",
                  }}
                  className="btn btn-dark my-3"
                  onClick={closeModal}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SplitBill;
