import React, { useState, useEffect } from "react";
import "../AdminLogin/admin.css";
// import Eye from "./img/eye.png";
// import AdminNavbar from "./AdminNavbar";
import errorimg from "../AdminLogin/img/errorimg.jpg";
import "../AdminLogin/Style.css";
import { useGlobalContext } from "../Context/Context";
// import Footer from "../Footer/footer";

function FinalReservation() {
  const [passwordShown, setPasswordShown] = useState(false);

  const {seat,setSeat} = useGlobalContext();

 

  return (
    
        <>
          
          <div className="loginContainer" style={{height:"100vh"}}>
            <div className="loginformcontainer">
              <form className="forminput">
              
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputname" style={{fontSize:"14px"}}>Total No. Of Persons</label>
                  <input
                    type="text"
                    // onChange={(e) => setFirstName(e.target.value)}
                    value={seat}
                    class="form-control"
                    readOnly
                    disabled
                    placeholder="First name"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="inputname" style={{fontSize:"14px"}}>Deposit Already Paid</label>
                  <input
                    type="text"
                    class="form-control"
                    // onChange={(e) => setLastName(e.target.value)}
                    value={"£0.00"}
                    readOnly
                    disabled
                    placeholder="Last Name"
                  />
                </div>
              </div>
             

                <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputname" style={{fontSize:"14px"}}>Deposit Paid</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Phone"
                    // onChange={(e) => setPhone(e.target.value)}
                    value={"£10.00"}
                    readOnly
                    disabled
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="inputname" style={{fontSize:"14px"}}>Payment Type</label>
                  <select
                    class="custom-select new-select"
                    id="inlineFormCustomSelect"
                    // value={seat}
                    // onChange={(e) => {
                    //   setSeat(e.target.value);
                    // }}
                  >
                    <option value="">Choose...</option>
                    <option value="Card">Card</option>
                    <option value="Cash">Cash</option>
                  </select>
                </div>
              </div>
             
                
                
                <button
                  type="submit"
                  className="btn Loginbtn"
                  // onClick={onSubmt}
                >
                  Submit
                </button>
              </form>
            </div>
            {/* <Footer /> */}
          </div>
        </>
      )
  
  
                }
export default FinalReservation
