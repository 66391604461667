import React from "react";
import "./admin.css";
function AdminNavbar({ hostname }) {
  return (
    <nav className="navbar  justify-content-between">
      <a
        style={{ marginLeft: "12px", color: "white" }}
        className="navbar-brand"
        href="http://sordiz.com/"
        target="blank"
      >
        SORDIZ {hostname}
      </a>
      <div className="form-inline">
        <a
          style={{ marginRight: "12px" }}
          className="nav-link active"
          aria-current="page"
          href="/help"
        >
          Help /Support
        </a>
      </div>
    </nav>
  );
}

export default AdminNavbar;
