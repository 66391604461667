import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../TableDetails/tableDetails.css";
import SORDIZ from "../../secret.js";
import { useGlobalContext } from "../Context/Context";


const Table = ({
  tabledetails,
  add,
  remove,
  tableHeaders,
}) => {
  const [loader, setLoader] = useState(false);
  const [popshow, setPopshow] = useState(false);
  const [catcherror, setCatchError] = useState(false);
  const [error, setError] = useState("");
  const [color, setColor] = useState("");
  const {customername,customeraddress,customerpostcode,customerphone,customertime} = useGlobalContext();
  console.log("data",customername,
  customeraddress,
  customerpostcode,
  customerphone,
  customertime);
  const { BASE_URL } = SORDIZ;
  var count = 0;
  const roundUptoTwoDecimalPlaces = (number) => {
    return Math.round(number * 100) / 100;
  };

  let subtotal = 0;
  tabledetails.forEach((item) => {
    subtotal += Number(item.price * item.productQty);
  });

  let postarr = [];
  for (let i = 0; i < tabledetails.length; i++) {
    postarr.push(tabledetails[i]);
  }

  const transformed = postarr.map(
    ({ productQty, description,  productID }) => ({
      qty: productQty,
      description: description,
      productID: productID,
    })
  );
  async function onSubmit() {
    if (tabledetails.length === 0) {
      alert("Select Some Product");
    } else {
      const url = `${BASE_URL}/SubmitTakeAwayOrder`;
      let data = {
        businessId: window.sessionStorage.getItem("businessId"),
        orderedItems: transformed,
        orderTotal: subtotal,
        takeAway:Boolean(window.sessionStorage.getItem("deliverytype")),
        delivery:Boolean(window.sessionStorage.getItem("deliverytype")),
        customerName: customername,
        fullAddress: customeraddress,
        postCode: customerpostcode,
        collectionTime: customertime,
        customerPhone: customerphone,
        paymentType: false
      };
      await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        redirect: "follow",
      })
        .then((response) => response.json())
        .then(async (response) => {
          console.log(response);
          if (response.status === 1) {
            setPopshow(true);
            setColor("#8e8e7c");
            setError(response.message);
            console.log(response.message);
          } else {
            setPopshow(true);
            setColor("#C70100");
            setError(response.message);
            console.log(response.message);
          }
        })
        .catch((err) => {
          setCatchError(true);
          setColor("#C70100");
        });
    }
  }

  // function tabclose() {
  //   window.close();
  // }

  return (
    <>
      {loader ? (
        <div className="loader-wrapper" id="loaderbox">
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      {catcherror ? (
        <>
          <div className="modalmaindiv" id="welcomeDiv">
            <div className="modaldiv">
              <div className="cardbox" style={{ backgroundColor: color }}>
                <div className="erroriconbox">
                  <i className="fa fa-exclamation-triangle"></i>
                </div>

                <br />
                <p className="text-center text-white">{error}</p>
                <br />
                <div className="mainerrorclosebtn">
                  <div
                    className="errorclosebtn"
                    onClick={() => {
                      setPopshow(false);
                      window.location.reload();
                    }}
                  >
                    <p>OK</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container" style={{ background: "beige" }}>
            <div
              className="d-flex"
              style={{ display: "inline-block", marginTop: "15px" }}
            >
              <div className="w-100">
                <div>
                  <Link className="textLink" to="/takeawaylist">
                    <button
                      className="btn"
                      style={{
                        width: "81px",
                        background: "rgb(88, 30, 30)",
                        color: "white",
                      }}
                    >
                      {" "}
                      Back
                    </button>
                  </Link>
                </div>
                <table
                  className="table table-bordered mt-2 table-striped"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr className="text-center">
                      {tableHeaders.map((item, index) => {
                        return (
                          <th key={index} scope="col">
                            {item}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    {tabledetails &&
                      tabledetails.map((item) => {
                        return (
                          <tr className="text-center">
                            <td>{item.description}</td>

                            <td></td>
                            <td>£{roundUptoTwoDecimalPlaces(item.price)}</td>
                            <td
                              style={{
                                maxWidth: "18px",
                                overflow: "hidden",
                                textOverflow: "clip",
                              }}
                            >
                              £
                              {roundUptoTwoDecimalPlaces(
                                item.price * (item.productQty || 1)
                              )}
                            </td>
                            <td
                              className="text-center"
                              style={{ cursor: "pointer", fontWeight: "bold" }}
                            >
                              <button
                                className="plusum"
                                onClick={(orderpartID) => {
                                  remove(item);
                                }}
                              >
                                -
                              </button>
                              <button
                                className="btn btn-outline border-0 font-weight-bold btn-sm p-0"
                                style={{
                                  cursor: "auto",
                                  marginLeft: "1px",
                                  marginRight: "1px",
                                }}
                              >
                                {item.productQty}
                              </button>{" "}
                              <button
                                className="plusum"
                                onClick={() => {
                                  add(item);
                                }}
                              >
                                +
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div>
                  <div className="buttoncontainer">
                    {tabledetails.map((itm) => {
                      count = count + (itm.productQty || 1) * itm.price;
                    })}

                    <button className="btn btn-outline border px-4 disabled">
                      {" "}
                      Sub Total: £{roundUptoTwoDecimalPlaces(count)}{" "}
                    </button>

                    <div>
                      <button
                        className="btn  "
                        style={{
                          width: "82px",

                          color: "white",
                          padding: "2px",
                          background: "rgb(88, 30, 30)",
                        }}
                        onClick={onSubmit}
                      >
                        Submit
                      </button>
                    </div>
                    <div>
                      <span
                        className="btn  "
                        style={{
                          width: "82px",

                          color: "white",
                          padding: "2px",
                          background: "rgb(88, 30, 30)",
                        }}
                      >
                        {/* <Payment /> */}Payment
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {popshow ? (
            <>
              <div className="modalmaindiv" id="welcomeDiv">
                <div className="modaldiv">
                  <div className="cardbox" style={{ backgroundColor: color }}>
                    {color === "#C70100" ? (
                      <>
                        <div className="erroriconbox">
                          <i className="fa fa-exclamation-triangle"></i>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="erroriconbox">
                          <i className="fa fa-badge-chech"></i>
                        </div>
                      </>
                    )}

                    <br />
                    <p className="text-center text-white">{error}</p>
                    <br />
                    <div className="mainerrorclosebtn">
                      {color === "#C70100" ? (
                        <>
                          <div
                            className="errorclosebtn"
                            id="close-pop"
                            onClick={() => {
                              setPopshow(false);
                            }}
                          >
                            <p>OK</p>
                          </div>
                        </>
                      ) : (
                        <div
                          className="errorclosebtn"
                          id="close-pop"
                          onClick={() => {
                            setPopshow(false);
                          }}
                        >
                          <a href="/TakeAwayList"><p>OK</p></a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default Table;
