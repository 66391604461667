import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../AdminLogin/Style.css";
import { useGlobalContext } from "../Context/Context";
import { useNavigate } from "react-router-dom"

function MyVerticallyCenteredModal(props) {
  const navigate = useNavigate()
  const {  customername,
    setCustomerName,
    customeraddress,
    setCustomerAddress,
    customerpostcode,
    setCustomerPostCode,
    customerphone,
    setCustomerPhone,
    customertime,
    setCustomerTime} = useGlobalContext();

// ! For Time Slot..........................................................................
     
  var today = new Date(),
    currtime = today.getHours() + ":" + today.getMinutes();

  let time2 = today.getHours() + ":" + (today.getMinutes() + 15);
  let time3 = today.getHours() + ":" + (today.getMinutes() + 30);
  let time4 = today.getHours() + ":" + (today.getMinutes() + 45);

  if(today.getMinutes() > 45){
    time2 = today.getHours() + 1 + ":" + (today.getMinutes() - 45)
  }else if(today.getMinutes() === 45){
    time2 = today.getHours() + 1+ ":" + (today.getMinutes() - today.getMinutes())
  }else{
    time2 = today.getHours() + ":" + (today.getMinutes() + 15);
  }
  if(today.getMinutes() > 30){
    time3 = today.getHours() + 1 + ":" + (today.getMinutes() - 30)
  }else if(today.getMinutes() === 30){
    time3 = today.getHours() + 1 + ":" + (today.getMinutes() - today.getMinutes())
  } else {
    time3 = today.getHours() + ":" + (today.getMinutes() + 30);
  }
  if (today.getMinutes() > 15) {
    time4 = today.getHours() + 1 + ":" + (today.getMinutes() - 15);
  } else if (today.getMinutes() === 15) {
    time4 =
      today.getHours() + 1 + ":" + (today.getMinutes() - today.getMinutes());
  } else {
    time4 = today.getHours() + ":" + (today.getMinutes() + 45);
  }
  console.log("time", time4);

// ! For Time Slot..........................................................................

  function onSubmit(e) {
    if (customername === "") {
      alert("Enter Name");
      e.preventDefault();
    } else if (customeraddress === "") {
      alert("Enter Address");
      e.preventDefault();
    } else if (customerpostcode === "") {
      alert("Enter Postcode");
      e.preventDefault();
    } else if (customerphone === "") {
      alert("Enter Phone Number");
      e.preventDefault();
    } else if (customertime === "") {
      alert("Select Collection Time");
      e.preventDefault();
    } else {
      // console.log("else selectedProduct");
      // setDeliveryType(false)
      window.sessionStorage.setItem("deliverytype",false)
      navigate("/TakeAwayOrderScreen")
      // window.open("/TakeAwayOrderScreen");
      
    }
  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body style={{ background: "#d7d2c2", marginTop: "-133px" }}>
        <div className="loginContainer2">
          <div className="loginformcontainer">
            <form className="forminput2">
              <div
                className="heading-takeaway"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h1>New TakeAway Order</h1>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputname">Full Name</label>
                  <input
                    type="text"
                    onChange={(e) => setCustomerName(e.target.value)}
                    value={customername}
                    class="form-control"
                    placeholder="First name"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="inputname">Postcode</label>
                  <input
                    type="text"
                    class="form-control"
                    onChange={(e) => setCustomerPostCode(e.target.value)}
                    value={customerpostcode}
                    placeholder="Postcode"
                  />
                </div>
              </div>
              <div class="form-group">
                <p className="DescHead">Address</p>
                <textarea
                  className="TextArea"
                  style={{ width: "100%" }}
                  onChange={(e) => setCustomerAddress(e.target.value)}
                  value={customeraddress}
                >
                  {" "}
                </textarea>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputname">Phone</label>  
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Phone"
                    onChange={(e) => setCustomerPhone(e.target.value)}
                    
                    value={customerphone}
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="inputname">Collection</label>
                  <select
                    class="custom-select new-select"
                    id="inlineFormCustomSelect"
                    value={customertime}
                    onChange={(e) => {
                      setCustomerTime(e.target.value);
                    }}
                  >
                    <option value="">Choose...</option>
                    <option value={time2}>{time2}</option>
                    <option value={time3}>{time3}</option>
                    <option value={time4}>{time4}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <button
                    className="btn"
                    style={{
                      width: "82px",
                      marginTop: "8px",
                      color: "white",
                      padding: "2px",
                      height: "129%",
                      background: "rgb(88, 30, 30)",
                    }}
                    onClick={props.onHide}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn  "
                    style={{
                      width: "82px",
                      marginTop: "8px",
                      color: "white",
                      padding: "2px",
                      height: "129%",
                      background: "rgb(88, 30, 30)",
                    }}
                    onClick={onSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Takeawayorder() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="success" onClick={() => setModalShow(true)}>
        Delivery
      </Button>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Takeawayorder;
