import React, { useEffect, useState } from "react";
import SORDIZ from "../../secret";
import Header from "../Header/header";
import printIMG from "../AdminLogin/img/printIMG.png";
import DeliveryModal from "../Modal/DeliveryModal";
import { Link } from "react-router-dom";
import Delieverytype from "./DelieveryType";

export default function TakeAwayList() {
  const { BASE_URL } = SORDIZ;
  const [loader, setLoader] = useState(false);
  const [takeawaydata, setTakeAwayData] = useState([]);
  const [hide, setHide] = useState(false);
  useEffect(() => {
     setLoader(true);
    setInterval(() => {
      async function newData() {
        fetch(
          `${BASE_URL}/GetTakeAwayOrders?businessId=` +
            window.sessionStorage.getItem("businessId")
        )
          .then((response) => response.json())
          .then((data) => {
            setLoader(false);
            // console.log("takedata",data);
            setTakeAwayData(data.data);
            setHide(true);
          });
      }
      newData();
    }, 5000);
  }, []);

  
  return (
    <>
      {loader ? (
        <div className="loader-wrapper" id="loaderbox">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          <Header />
          <div
            style={{
              position: "relative",
              top: "-42px",
              display: "inline-block",
            }}
          >
            <Link className="textLink" to="/Homepage">
              <button
                className="btn"
                style={{
                  width: "59px",
                  background: "rgb(171 171 171)",
                  color: "black",
                  border: "1px solid white",
                }}
              >
                {" "}
                Back
              </button>
            </Link>
          </div>
          <Delieverytype />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
            className="cardContainer"
          >
            {takeawaydata.map((item) => {
              return (
                <>
                  <div
                    style={{
                      margin: "5px",
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      border: "2px solid #c5c1c1",
                      borderRadius: "8px",
                    }}
                  >
                    <div class="card text-center" style={{ width: "300px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          fontSize: "13px",
                          height: "34px",
                        }}
                        class="card-header"
                      >
                        <p style={{ fontWeight: "700" }} class="card-text">
                          {item.orderNumber}
                        </p>

                        <p style={{ fontWeight: "700" }} class="card-text">
                          {item.collectionTime}
                        </p>
                        <div>
                          <p
                            class="card-text"
                            style={{ color: "green", fontWeight: "700" }}
                          >
                            {item.orderTotal === null
                              ? "£29.42"
                              : `£${Math.round((item.orderTotal + Number.EPSILON) * 100) / 100}`}                      
                          </p>
                        </div>
                      </div>
                      <div style={{ background: "#e3e3e3" }} class="card-body">
                        <p
                          class="card-title"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "15px",
                            fontWeight: "600",
                            position: "relative",
                            top: "-15px",
                          }}
                        >
                          <span className="text-success font-weight-bold">
                            {" "}
                            {item.takeAway?"PICK UP" : "DELIEVERY"}
                          </span>
                          {item.paymentType ? "(PAID)" : "(COD)"}
                        </p>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "2px",
                          }}
                        ></div>
                        <div
                          style={{
                            borderRadius: "4px",
                            padding: "5px",
                            background: "#ffffff94",
                            height: "10rem",
                            overflowY: "scroll",
                            boxShadow: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            position: "relative",
                            top: "-23px",
                          }}
                          className="itemlist"
                        >
                          {item.orderedItems.map((e, i) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                fontSize: "11px",
                              }}
                              className="items"
                            >
                              <p>{e.description}</p>
                              <p>*{e.qty}</p>
                            </div>
                          ))}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "-13px",
                          }}
                          className="btnfooter"
                        >
                          <div className="mr-3">
                            <button
                              style={{
                                background: "none",
                                border: "2px solid #c5c1c1",
                                width: "60px",
                                padding: "0.375rem",
                                fontSize: "1rem",
                                lineHeight: "1.1",
                                borderRadius: "4px",
                              }}
                            >
                              {" "}
                              Pay
                            </button>
                          </div>
                          <div>
                            {" "}
                            <DeliveryModal item={item} />
                          </div>
                          <Link
                            to="/KitchenPrint"
                            state={{
                                  item: item
                                }}
                                style={{ textDecoration: "none" }}
                          >
                            <button
                              style={{ border: "none", background: "none" }}
                            >
                              {" "}
                              <img
                                style={{ width: "15px" }}
                                src={printIMG}
                                // onClick={() => {
                                //         setPopshow(false);
                                //       }}
                                alt="print"
                              />{" "}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}
