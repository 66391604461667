import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import SORDIZ from "../../secret";

const AppContext = React.createContext();
const GetApiUrl = "https://api.sordiz.com/GetBusiness?hostname=tcbwigan";

function AppProvider({ children }) {
  const { BASE_URL } = SORDIZ;
  const [businessdata, setBusinessdata] = useState([]);
  const [isshow, setIsshow] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginData, setloginData] = useState([]);
  const [customername, setCustomerName] = useState("");
  const [customeraddress, setCustomerAddress] = useState("");
  const [customerpostcode, setCustomerPostCode] = useState("");
  const [customerphone, setCustomerPhone] = useState("");
  const [customertime, setCustomerTime] = useState("");

  //!for New REservations..................................
  const [seat, setSeat] = useState("");
  //!................................................ 

  const getData = async (url) => {
    const response = await fetch(url);
    const Apidata = await response.json();
    setIsshow(true);
    setBusinessdata(Apidata.data.hostname);
  };

  useEffect(() => {
    getData(GetApiUrl);
  }, []);
  async function onSubmt(e) {
    e.preventDefault();

    await fetch(
      `${BASE_URL}/UserLogin?UserCode=${username}&DailyPin=${password}&Hostname=${businessdata}`
    )
      .then((response) => response.json())
      .then(async (response) => {
        if (response.hostname === businessdata) {  
          setloginData(response);
          window.sessionStorage.setItem("token", response.authToken);
          window.sessionStorage.setItem("businessId", response.businessId);
          window.sessionStorage.setItem("userid",response.userID)
          window.open("/Homepage", "_self");
        }
      });
  }

  return (
    <AppContext.Provider
      value={{
        setBusinessdata,
        businessdata,
        isshow,
        setUsername,
        username,
        password,
        setPassword,
        onSubmt,
        loginData,
        customername,
        setCustomerName,
        customeraddress,
        setCustomerAddress,
        customerpostcode,
        setCustomerPostCode,
        customerphone,
        setCustomerPhone,
        customertime,
        setCustomerTime,
        seat,setSeat
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppProvider, AppContext, useGlobalContext };
