import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../AdminLogin/Style.css";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../Context/Context";

function MyVerticallyCenteredModal(props) {
  const {
    customername,
    setCustomerName,
    customerphone,
    setCustomerPhone,
    customertime,
    setCustomerTime,
  } = useGlobalContext();
  const navigate = useNavigate();

// For Time Slot////////////////////////////////////////////////////////////////

  var today = new Date(),
  currtime = today.getHours() + ":" + today.getMinutes();

  let time2 = today.getHours() + ":" + (today.getMinutes() + 15);
  let time3 = today.getHours() + ":" + (today.getMinutes() + 30);
  let time4 = today.getHours() + ":" + (today.getMinutes() + 45);

  if (today.getMinutes() > 45) {
    time2 = today.getHours() + 1 + ":" + (today.getMinutes() - 45);
  } else if (today.getMinutes() === 45) {
    time2 = 
      today.getHours() + 1 + ":" + (today.getMinutes() - today.getMinutes());
  } else {
    time2 = today.getHours() + ":" + (today.getMinutes() + 15);
  }
  if (today.getMinutes() > 30) {
    time3 = today.getHours() + 1 + ":" + (today.getMinutes() - 30);
  } else if (today.getMinutes() === 30) {
    time3 =
      today.getHours() + 1 + ":" + (today.getMinutes() - today.getMinutes());
  } else {
    time3 = today.getHours() + ":" + (today.getMinutes() + 30);
  }
  if (today.getMinutes() > 15) {
    time4 = today.getHours() + 1 + ":" + (today.getMinutes() - 15);
  } else if (today.getMinutes() === 15) {
    time4 =
      today.getHours() + 1 + ":" + (today.getMinutes() - today.getMinutes());
  } else {
    time4 = today.getHours() + ":" + (today.getMinutes() + 45);
  }
  console.log("time", time4);

// For Time Slot////////////////////////////////////////////////////////////////


  function onSubmit(e) {
    if (customername === "") {
      alert("Enter Name");
      e.preventDefault();
    } else if (customerphone === "") {
      alert("Enter Phone Number");
      e.preventDefault();
    } else if (customertime === "") {
      alert("Select Collection Time");
      e.preventDefault();
    } else {
      // window.open("/TakeAwayOrderScreen");
      window.sessionStorage.setItem("deliverytype", false);
      navigate("/TakeAwayOrderScreen");
    }
  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body style={{ background: "#d7d2c2", marginTop: "-266px" }}>
        <div className="loginContainer2">
          <div className="loginformcontainer">
            <form className="forminput2">
              <div
                className="heading-takeaway"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h1>New TakeAway Order</h1>
              </div>
              <div class="row new-detail">
                <div class="form-group col-md-6">
                  <label for="inputname">Full Name</label>
                  <input
                    type="text"
                    onChange={(e) => setCustomerName(e.target.value)}
                    value={customername}
                    class="form-control"
                    placeholder="Full Name"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="inputname">Phone</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Phone"
                    onChange={(e) => setCustomerPhone(e.target.value)}
                    value={customerphone}
                  />
                </div>
              </div>

              <div class="row">
                <label for="inputname">Collection</label>
                <select
                  class="custom-select mr-sm-2"
                  id="inlineFormCustomSelect"
                  value={customertime}
                  onChange={(e) => {
                    setCustomerTime(e.target.value);
                  }}
                >
                  <option value="">Choose...</option>
                  <option value={time2}>{time2}</option>
                  <option value={time3}>{time3}</option>
                  <option value={time4}>{time4}</option>
                </select>
                {/* </div> */}
              </div>
              <div class="row">
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <button
                    className="btn"
                    style={{
                      width: "82px",
                      marginTop: "8px",
                      color: "white",
                      padding: "2px",
                      height: "129%",
                      background: "rgb(88, 30, 30)",
                    }}
                    onClick={props.onHide}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn  "
                    style={{
                      width: "82px",
                      marginTop: "8px",
                      color: "white",
                      padding: "2px",
                      height: "129%",
                      background: "rgb(88, 30, 30)",
                    }}
                    onClick={onSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function PickupModal() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="secondary" onClick={() => setModalShow(true)}>
        Pickup
      </Button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default PickupModal;
