import React from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Tableimg from "../AdminLogin/img/table.jpg";
import Takeaway from "../AdminLogin/img/takeaway.jpg";
import Reserved from "../AdminLogin/img/Reserved.jpg";
import "../Home/Homepage.css";

export default function Homepage() {
  let isstable = JSON.parse(localStorage.getItem("isTable"));
  let istakeaway = JSON.parse(localStorage.getItem("istakeaway"));
  let isreserve = JSON.parse(localStorage.getItem("isreserve"));
  function Table() {
    localStorage.setItem("isTable", true);
    localStorage.setItem("isreserve", false);
    localStorage.setItem("istakeaway", false);
    window.open("/home", "_self");
  }
  function takeaway() {
    localStorage.setItem("istakeaway", true);
    localStorage.setItem("isTable", false);
    localStorage.setItem("isreserve", false);
    window.open("/takeawaylist", "_self");
  }
  function reservation() {
    localStorage.setItem("istakeaway", false);
    localStorage.setItem("isTable", false);
    localStorage.setItem("isreserve", true);
    window.open("/reservation", "_self");
  }
  return (
    <>
      <div style={{ backgroundColor: "beige", height: "100vh" }}>
        <Link className="textLink" to="/login">
          <button
            className="btn"
            style={{
              width: "81px",
              background: "rgb(88, 30, 30)",
              color: "white",
            }}
          >
            {" "}
            Back
          </button>
        </Link>
        <div className="imp">
          <Card
            style={{
              width: "20rem",
              boxShadow:
                "rgb(74 43 26) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 2px 4px 0px",
            }}
          >
            <Card.Img
              variant="top"
              src={Tableimg}
              style={{ height: "256px" }}
            />
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Card.Title
                style={{
                  color: "#3d2f24",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                NEW ORDER
              </Card.Title>
              <Card.Text style={{ display: "flex", justifyContent: "center" }}>
                Table List Will Be Appear
              </Card.Text>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="btn btn-dark my-3"
                  style={{
                    width: "81px",
                    height: "50px",
                    backgroundColor: "rgb(88, 30, 30)",
                  }}
                  onClick={Table}
                >
                  GO
                </button>
              </div>
            </Card.Body>
          </Card>
          <Card
            style={{
              width: "20rem",
              boxShadow:
                "rgb(238 147 122) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 2px 4px 0px",
            }}
          >
            <Card.Img
              variant="top"
              src={Takeaway}
              style={{ height: "256px" }}
            />
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Card.Title
                style={{
                  color: "#3d2f24",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                TAKE AWAYS
              </Card.Title>
              <Card.Text style={{ display: "flex", justifyContent: "center" }}>
                Take Aways Order Will Be Appear
              </Card.Text>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <a href="/takeawaylist">
                  {" "}
                  <button
                    className="btn btn-dark my-3"
                    style={{
                      width: "81px",
                      height: "50px",
                      backgroundColor: "rgb(88, 30, 30)",
                    }}
                    onClick={takeaway}
                  >
                    GO
                  </button>
                </a>
              </div>
            </Card.Body>
          </Card>
          <Card
            style={{
              width: "20rem",
              boxShadow:
                "rgb(62 142 185) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 2px 4px 0px",
            }}
          >
            <Card.Img
              variant="top"
              src={Reserved}
              style={{ height: "256px" }}
            />
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Card.Title
                style={{
                  color: "#3d2f24",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                RESERVATION
              </Card.Title>
              <Card.Text style={{ display: "flex", justifyContent: "center" }}>
                Table Will Be Reserved Here
              </Card.Text>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="btn btn-dark my-3"
                  style={{
                    width: "81px",
                    height: "50px",
                    backgroundColor: "rgb(88, 30, 30)",
                  }}
                  onClick={reservation}
                >
                  GO
                </button>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
