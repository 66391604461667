import React from "react";
import { MDBFooter } from "mdb-react-ui-kit";

export default function Footer() {
  return (
    <div>
      <MDBFooter
        className="text-center text-white"
        // style={{ backgroundColor: "#ebebde" }}
      >
        <div className="text-center text-dark p-3">
          © 2022 Copyright:{" "}
          <a className="text-dark text-decoration-none" href="/home">
            SORDIZ.COM
          </a>
          <p className="small">V-0.0.01</p>
        </div>
      </MDBFooter>
    </div>
  );
}
 