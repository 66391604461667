 import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "../components/Payment.css"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "3px solid grey",
    borderRadius: "12px",
    overflowX: "hidden",
    height: "100vh",
    width: "45vw",
    backgroundColor: "beige",
  },
};


// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function Payment() {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [orderList, setOrderList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let tsum = 0

  orderList.forEach(item =>{
    tsum += Number(item.Price)
  })

  useEffect(() => {
    async function fetchData() {
      const path = window.location.pathname.split("/");
      fetch(
        ` http://api.sordiz.com/v3/GetTableOrder?orderid=${path[2]}&UserId=208`
      )
        .then((response) => response.json())
        .then((data) => setOrderList(
          data.tableProducts,
          orderList.map(item => {
            return {
              select: false,
              id: item.id
            }
          })
          ));
    }
    fetchData();
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  
  return (
    <div >
      <button
        className="px-4 py-1"
        style={{
          backgroundColor: "transparent",
          height: "35px",
          border: "none",
         fontSize:"11px",
          color: "white",
          display:"contents"
        }}
        
        onClick={openModal}
        
      >
        PAY NOW
      </button>
      <Modal
       show={show}
       onHide={handleClose}
       backdrop="static"
       keyboard={false}
       isOpen={modalIsOpen}
       style={customStyles}
       contentLabel="Example Modal"
      >
        <div className="nav justify-content-center">
          <h5
            className="text-black"
            ref={(_subtitle) => (subtitle = _subtitle)}
            style={{textAlign:"left",fontSize:"25px",textDecoration:"underline"}}
          >
            YOUR BILL
          </h5>
      
        </div>
        <div>
          <div className="nav justify-content-start" style={{position:"absolute"}} >
            <label >Tabel Number = </label>
            <input disabled style={{border:"none", width:"40px",paddingLeft: "9px",borderRadius:"9px", background: "#80808078" }} value={window.sessionStorage.getItem("tableno")}></input>
          </div>
          <div className="nav justify-content-end">
            <label >Bill Amount = £</label>
            <input disabled style={{border:"none", width:"50px",paddingLeft: "9px",borderRadius:"9px", background: "#80808078" }} value={tsum}></input>
           </div>
        </div>
        <div className="payment-data">
          <table style={{ marginTop: "12px" }}className="table-bordered">
            <thead>
              <tr className=" py-2" style={{backgroundColor:"rgb(168 168 159)",color:"black"}}>
          
                <th
                  scope="col"
                  style={{ width: "92%", padding: "10px 5px 10px 5px" }}
                >
                  Description
                </th>
                <th scope="col" style={{ padding: "10px 5px 10px 5px" }}>
                  Price
                </th>
              </tr>
            </thead>
            
            <tbody>
              
              {orderList &&
                orderList.map((item, index) => {
                  return (
                    <tr
                      key={item.id}>
                     
                      
                      <th scope="row" >{item.Description}</th>
                      <td>£{item.Price}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div >
            <div style={{display:"flex",justifyContent:"space-evenly"}}>
              <div>
                <button style={{width:"81px", border: "3px solid", backgroundColor:"rgb(88, 30, 30)"}}className="btn btn-dark my-3">
                  <a>
                  PAY</a>
                </button>
              </div>
              <div>
                <button
                style={{width:"81px",border: "3px solid", backgroundColor:"rgb(88, 30, 30)"}}
                  className="btn btn-dark my-3"
                  onClick={closeModal}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Payment;

