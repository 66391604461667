import Button from "react-bootstrap/Button";
import SORDIZ from "../../secret";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./tableDetails.css";
import React, { useState, useEffect } from "react";

function MyVerticallyCenteredModal(props) {
  const { BASE_URL } = SORDIZ;
  const [voucherdata, setvoucherdata] = useState([]);
  const [show, setShow] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const VoucherCodeUrl =
    `${BASE_URL}/GetVoucherDetails?voucherCode=GV8191706&orderId=854c7896-c0e9-4e8f-a304-37d03c453047&UserId=208`;
  const voucherfetch = async () => {
    const response = await fetch(VoucherCodeUrl);
    const data = await response.json();
    setvoucherdata(data);
    console.log(voucherdata);
  };
  const submitUrl = "http://api.sordiz.com/v2/RedeemVoucher";
  const Vouchersubmit = async () => {
    let data = {
      Amount: "0",
      Description: "50% On Adult Buffet",
      DiscountAmount: 0.0,
      ForeName: null,
      IsValid: true,
      Message: null,
      Mobile: "9999907843",
      NoOfGuests: 0,
      OrderId: "df3217b6-fd4b-4feb-83fb-566fe2d5d249",
      Price: 0.0,
      ProductId: 0,
      Restaurant: null,
      SurName: null,
      Used: false,
      UserId: 0,
      VariableAmount: false,
      VoucherCode: "PR9625821",
      VoucherStatus: "REDEEMED",
      VoucherType: null,
      VoucherValue: "PBA50",
    };
    await fetch(submitUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then(async (response) => {
        console.log(response);
      });
  };

  const handleClose = () => setShow(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Modal
        {...props}
        onHide={handleClose}
        keyboard={false}
        isOpen={modalIsOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            CHECK CODE
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontSize: "1.1rem",
          }}
        >
          <div className="headerCheck">
            <div className="codeinput">
              <p>CODE</p>
              <p>:</p>
              <input
                style={{ width: "44%", borderRadius: "5px", height: "36px" }}
                type="text"
              />
            </div>
            <div className="codeinput">
              <p>TYPE</p>
              <p>:</p>
              <Form.Select
                style={{ height: "36px", width: "44%" }}
                aria-label="Default select example"
              >
                <option></option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button className="searchbtn" onClick={voucherfetch}>
                search
              </button>
            </div>
          </div>
          <div className="desciptionCheck">
            <h4 style={{ color: "grey", fontSize: "1.1rem" }}>Desciption</h4>
            <div className="codeinput">
              <p>PROMOTION</p>
              <p>:</p>
              <input
                style={{ width: "44%", borderRadius: "5px", height: "36px" }}
                type="text"
              />
            </div>
            <div className="codeinput">
              <p>STATUS</p>
              <p>:</p>
              <input
                style={{ width: "44%", borderRadius: "5px", height: "36px" }}
                type="text"
              />
            </div>
            <div className="codeinput">
              <p>MOBILE</p>
              <p>:</p>
              <input
                style={{ width: "44%", borderRadius: "5px", height: "36px" }}
                type="text"
              />
            </div>
            <div className="codeinput">
              <p>VALUE</p>
              <p>:</p>
              <input
                style={{ width: "44%", borderRadius: "5px", height: "36px" }}
                type="text"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "space-around" }}>
          <Button onClick={Vouchersubmit} disabled={voucherdata.IsValid}>
            Use
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function CheckModal() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <button
        style={{
          backgroundColor: "transparent",
          height: "35px",
          border: "none",
          fontSize: "11px",
          color: "white",
          display: "contents",
        }}
        onClick={() => setModalShow(true)}
      >
        CHECK CODE
      </button>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default CheckModal;
