import React, { useEffect, useState } from "react";
import "../../../src/style/style.css";
import MenuTable from "../Menu/menu";
import Thetable from "../TableDetails/tableDetails";
import Header from "../Header/header";
import Footer from "../Footer/footer";
import SORDIZ from "../../secret";

const OrderScreen = () => {
  const { BASE_URL } = SORDIZ;
  const [state, updateState] = useState();
  const [tableProducts, updateTableproducts] = useState();
  const [loader, setLoader] = useState(false);
  const [orderguid, setOrderGUID] = useState(
    "00000000-0000-0000-0000-000000000000"
  );

  useEffect(() => {
    async function fetchData() {
      setLoader(true);
      const path = window.location.pathname.split("/");
      fetch(
        `${BASE_URL}/GetTableOrder?orderid=${window.sessionStorage.getItem(
          "orderid"
        )}&UserId=208`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token"),
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          window.sessionStorage.setItem("adcount", data.tableDetails.AdCount);
          updateState(data);
          window.sessionStorage.setItem(
            "tableno",
            data.tableDetails.TableNumber
          );
          updateTableproducts(data.tableProducts);
          setLoader(false);
        });
    }
    fetchData();
  }, []);
  
  // add quantity is a function to increase quantity of the product on click of the '+' icon and the menu item
  const addQuantitiy = (item) => {
    // copy table products in newArray
    
    var newArray = tableProducts;
    // find index of the item in the array
    const index = newArray.findIndex((object) => {
      // check if orderPartId is available than find index on that basis or find on the basis of ProductId
      return object.orderPartID && object.orderPartID !== 0
        ? object.orderPartID === item.orderPartID
        : object.productID === item.productID;
      // return object.OrderPartID === item.OrderPartID;
    });
    if (index !== -1) {
      // if quantity is 0 than increase by 2 else increase by 1
      newArray[index].productQty === 0
        ? (newArray[index].productQty = newArray[index].productQty + 2)
        : (newArray[index].productQty = newArray[index].productQty + 1);
      // update state
      updateTableproducts([...newArray]);
    }
  };
  // subtranct quantity is a function to decrease quantity of the product
  const subtractQuantity = (item) => {
    // copied the table products array in a variable
    var newArray = tableProducts;
    // calculate the index of the arrays
    const index = newArray.findIndex((object) => {
      // check if orderPartId is available than find index on that basis or find on the basis of ProductId
      return object.orderPartID && object.orderPartID !== 0
        ? object.orderPartID === item.orderPartID
        : object.productID === item.productID;
    });
    // if index is found
    if (index !== -1) {
      // if product quantity is greater than 1 than decrease quantity by 1
      if (newArray[index].productQty > 1) {
        newArray[index].productQty = newArray[index].productQty - 1;
        // update state
        updateTableproducts([...newArray]);
      } else {
        // if product quantity is already at 1 that is lowest than remove item from the orderlist
        const filteredArray = newArray.filter((data) =>
          data.orderPartID && data.orderPartID !== 0
            ? data.orderPartID !== item.orderPartID
            : data.productID !== item.productID
        );
        // update state
        updateTableproducts([...filteredArray]);
      }
    }
  };

  // function to add selected menu items to order list
  const addToState = (item) => {
    // check if the item is already available in the list
  
    var newArray = tableProducts;
    // find index of the item in the array
    const index = newArray.findIndex((object) => {
      // check if orderPartId is available than find index on that basis or find on the basis of ProductId
      return object.orderPartID && object.orderPartID !== 0
        ? object.orderPartID === item.orderPartID
        : object.productID === item.productID;
    });
    if (index !== -1) {
      // if index is available than increase quantity of  product in the cart
      addQuantitiy(item);
    } else {
    // else add the product in the cart
    item.productQty = item.productQty + 1
   
    updateTableproducts(() => [...tableProducts, item]);
    }
  };

  //Add quantity in existing quantity
  const formatProductList = (bigdata) => {
    const group = {};
    bigdata.forEach((e) => {
      const groupObjects = (group[e.productID] = group[e.productID] || {
        ...e,
        productQty: 0,
      });
      groupObjects.productQty += e.productQty;
    });
    const res = Object.values(group);
    return res;
  };

  const tableHeaders = ["Product", "Options", "Price", "Total", "Actions"];

  return (
    <>
      {loader ? (
        <div className="loader-wrapper" id="loaderbox">
          <div className="loader"></div>
        </div>
      ) : (
        ""
      )}
      <div style={{ background: "beige", height: "100vh" }} className="whole">
        <div className="">
          <Header />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "beige",
          }}
        >
          <Thetable
            tabledetails={tableProducts ? formatProductList(tableProducts) : []}
            updateTableproducts={updateTableproducts}
            add={addQuantitiy}
            remove={subtractQuantity}
            tableHeaders={tableHeaders}
            orderguid={orderguid}
            setOrderGUID={setOrderGUID}
          />
          <MenuTable add={addToState} />
        </div>

        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default OrderScreen;
