import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../AdminLogin/Style.css";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../Context/Context";

function MyVerticallyCenteredModal(props) {
  const navigate = useNavigate();
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  // const [seat, setSeat] = useState("");
  const [highchair, setHighChair] = useState("");
  const [wheelchair, setWheelChair] = useState("");
  const [notes, setNotes] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [timeslots, setTimeSlots] = useState([
    "Choose",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
  ]);
  const {seat,setSeat} = useGlobalContext();


    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today =yyyy + '-' + mm + '-' + dd

    function onSubmit(e) {
      if (firstname === "") {
        alert("Enter First Name");
        e.preventDefault();
      } else if (lastname === "") {
        alert("Enter Last Name");
        e.preventDefault();
      } else if (phone === "") {
        alert("Enter Phone Number");
        e.preventDefault();
      } else if (seat === "") {
        e.preventDefault();
        alert("Enter Number Of Seat");
      }  else if(highchair === "" && wheelchair === ""){
        alert("Select High Chair or Wheel Chair")
        e.preventDefault();
      }
      // else if(Number(highchair) + Number(wheelchair) > Number(seat)){
      //   alert("Sum of High Chair & Wheel Chair must be equal to No. of Seats")
      //   e.preventDefault();
      // }
      else if (date === "") {
        alert("Select Date");
        e.preventDefault();
      }else if (time === ""){
        alert("Select Available Time Slot")
      }
      else {
        // e.preventDefault();
        console.log("step");
        navigate("/FinalReservation");
        // <AdditionalContentExample/>
      }
    }

   

   
  
    

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body style={{ background: "#d7d2c2", marginTop: "-22px" }}>
        <div className="loginContainer2">
          <div className="loginformcontainer">
            <form className="forminput2">
              <div
                className="heading-takeaway"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <h1>New Reservation </h1>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputname">First Name</label>
                  <input
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstname}
                    class="form-control"
                    placeholder="First name"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="inputname">Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastname}
                    placeholder="Last Name"
                  />
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputname">Phone</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Phone"
                    onChange={(e) => setPhone(e.target.value)}
                    onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}
                    value={phone}
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="inputname">Seats</label>
                  <select
                    class="custom-select new-select"
                    id="inlineFormCustomSelect"
                    value={seat}
                    onChange={(e) => {
                      setSeat(e.target.value);
                    }}
                  >
                    <option value="">Choose...</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="inputname">No. Of HighChairs</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="No. Of HighChairs"
                    onChange={(e) => setHighChair(e.target.value)}
                    onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}
                    value={highchair}
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="inputname">No. Of WheelChairs</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="No. Of WheelChairs"
                    onChange={(e) => setWheelChair(e.target.value)}
                    onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}
                    value={wheelchair}
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-3">
                  <label for="inputname">Date</label>
                  <input
                    type="date"
                    class="custom-select new-select"
                    id="inlineFormCustomSelect"
                    value={date}
                    min={today} 
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                  ></input>
                </div>
                <div
                  class="form-group col-md-3"
                  style={{ position: "relative", left: "112px" }}
                >
                  <label for="inputname">Time</label>
                  <select
                    class="custom-select new-select"
                    id="inlineFormCustomSelect"
                    value={time}
                    onChange={(e) => {
                      setTime(e.target.value);
                    }}
                  >
                    {timeslots.map((value, index) => {
                      return (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div class="form-group">
                <p className="DescHead">Notes</p>
                <textarea
                  className="TextArea"
                  style={{ width: "100%" }}
                  onChange={(e) => setNotes(e.target.value)}
                  value={notes}
                >
                  {" "}
                </textarea>
              </div>
              <div class="row">
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <button
                    className="btn"
                    style={{
                      width: "82px",
                      marginTop: "8px",
                      color: "white",
                      padding: "2px",
                      height: "129%",
                      background: "rgb(88, 30, 30)",
                    }}
                    onClick={props.onHide}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn  "
                    style={{
                      width: "82px",
                      marginTop: "8px",
                      color: "white",
                      padding: "2px",
                      height: "129%",
                      background: "rgb(88, 30, 30)",
                    }}
                    onClick={onSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function NewReservation() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Button variant="success" onClick={() => setModalShow(true)}>
        New Reservation
      </Button>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default NewReservation;
