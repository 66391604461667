import React, { useState, useEffect } from "react";
import "./admin.css";
import Eye from "./img/eye.png";
import AdminNavbar from "./AdminNavbar";
import errorimg from "../AdminLogin/img/errorimg.jpg";
import "./Style.css";
import { useGlobalContext } from "../Context/Context";
import Footer from "../Footer/footer";

function LoginApp() {
  const [passwordShown, setPasswordShown] = useState(false);

  const {
    businessdata,
    isshow,
    setUsername,
    username,
    password,
    setPassword,
    onSubmt,
  } = useGlobalContext();

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <>
      {isshow ? (
        <>
          <div className="adminNav">
            <AdminNavbar hostname={businessdata} />
          </div>
          <div className="loginContainer" >
            <div className="loginformcontainer">
              <form className="forminput">
              
                <div className="mb-3">
                  <label for="exampleInput" className="form-label">
                    User Name
                  </label>
                  <input
                    type="username"
                    className="form-control"
                    id="exampleInput"
                    aria-describedby="emailHelp"
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    value={username}
                  />
                </div>
                <div className="mb-3">
                  <label for="exampleInputPassword1" className="form-label">
                    Password
                  </label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    className="form-control"
                    id="exampleInputPassword1"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                  />
                  <img
                    className="eye-img"
                    src={Eye}
                    onClick={togglePasswordVisibility}
                    style={{ cursor: "pointer" }}
                  ></img>
                </div>

                <button
                  type="submit"
                  className="btn Loginbtn"
                  onClick={onSubmt}
                >
                  Submit
                </button>
              </form>
            </div>
            <Footer />
          </div>
        </>
      ) : (
        <div className="error-img">
          <img src={errorimg}></img>
        </div>
      )}
    </>
  );
}

export default LoginApp;
