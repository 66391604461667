import React, { useState } from "react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import SORDIZ from "../../secret.js";
import "./Modal.css";
import { useGlobalContext } from "../Context/Context";


function DeliveryModalPopUp({ item }) {
  const { BASE_URL } = SORDIZ;
  const [show, setShow] = useState(false);
  const [staffdata, setStaffData] = useState([]);
  const [hide, setHide] = useState(false);
  const [staffnumber, setStaffNumber] = useState("");
  const [staffid,setStaffID] = useState("")
  const [firststaffnumber, setFirstStaffNumber] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
  useEffect(() => {
    const url =
      `${BASE_URL}/GetDeliveryStaff?businessId=` +
      window.sessionStorage.getItem("businessId");
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setStaffData(data.data);
        setFirstStaffNumber(data.data[0].mobile);
      });
  }, []);

  function details(e) {
    setHide(true);
    for (let i = 0; i < availablestaff.length; i++) {
      if (availablestaff[i].id === Number(e.target.value)) {
        setStaffNumber(availablestaff[i].mobile);
        setStaffID(availablestaff[i].deliveryStaffId)
      }
    }
  }
  async function  assign(){
    const url =  `${BASE_URL}/UpdateTakeAwayOrderDelivery`;
    let data = {
      orderGUID: item.takeAwayOrderId,
      deliveryStaffId: staffid,
      userId:Number(window.sessionStorage.getItem("userid")),
      amount:item.orderTotal,
      delInd:false,
      orderNo:item.orderNumber
    };
    await fetch(url,{
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      redirect: "follow",
    })
    .then((response) => response.json())
    .then(async (response) => {
      console.log(response);
      if (response.status === 1) {
        // setPopshow(true);
        // setColor("#8e8e7c");
        // setError(response.message);
        console.log(response.message);
      } else {
        // setPopshow(true);
        // setColor("#C70100");
        // setError(response.message);
        console.log(response.message);
      }
    })
    .catch((err) => {
      // setCatchError(true);
      // setColor("#C70100");
    });
  }

let availablestaff = []
let num = []
for(let i = 0 ; i< staffdata.length; i++){
  if(staffdata[i].available === true){
    availablestaff.push(staffdata[i])
    num.push(availablestaff[0].deliveryStaffId)
  }
}
if(staffid === undefined){
  setStaffID(num[0])
}

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        Collect
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Delivery Details</Modal.Title>
          <div className="font-weight-bold ml-5 mt-2 text-center">
            <p>(Cash on Delivery)</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex mb-2">
            <div>
              <label className="font-weight-bold">Order No </label>
              <input type="text" value={item.orderNumber} readOnly disabled></input>
            </div>
            <div>
              <label className="font-weight-bold" >Amount </label>
              <input type="text" value= {item.orderTotal === null ? "£29.42" : `£${item.orderTotal}`} readOnly disabled></input>
            </div>
          </div>
          {item.takeAway? "":
           <div className="d-flex flex-column mb-3">
           <div className="d-flex flex-column" id="DeliveryStaffDetails">
             <label for="delivery" className="mr-3 font-weight-bold">
               Delivery Staff
             </label>

             <select
               name="deliveryGuys"
               id="deliveryStaff"
               onChange={(e) => details(e)}
             >
               {availablestaff.map((value, index) => {
                 return (
                   <option key={index} value={value.id}>
                     {value.name}
                   </option>
                 );
               })}
             </select>
           </div>

           <div>
             <div>
               <label className="font-weight-bold mr-5">Phone No</label>
               <br></br>
               <input
                 type="text"
                 value={hide ? staffnumber : firststaffnumber}
                 readOnly
                 disabled
               ></input>
             </div>
           </div>
         </div>
          }
         
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around">
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Link 
            to="/CustomerPrint"
            state={{
              item: item
            }}
            style={{ textDecoration: "none" }}
          >
            <Button variant="success"  alt="print" onClick={assign}>
             Save
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeliveryModalPopUp;
