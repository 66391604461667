import React from "react";
import "../HeaderComponents/ProductRequest.css"
export default function ProductRequest() {

    return <>
        <div>
            <h1>Product Request</h1>
        </div>
   
    </>
}