import React from "react";
import "../Header/header.css";
import DP from "../Header/UserPNG.png";

export default function Header() {
  let isstable = JSON.parse(localStorage.getItem("isTable"));
  let istakeaway = JSON.parse(localStorage.getItem("istakeaway"));
  let isreserve = JSON.parse(localStorage.getItem("isreserve"));
  function sordiz() {
    window.open("http://sordiz.com/", "_self");
  }
  function Table() {
    localStorage.setItem("isTable", true);
    localStorage.setItem("isreserve", false);
    localStorage.setItem("istakeaway", false);
    window.open("/home", "_self");
  }
  function takeaway() {
    localStorage.setItem("istakeaway", true);
    localStorage.setItem("isTable", false);
    localStorage.setItem("isreserve", false);
    window.open("/takeawaylist", "_self");
  }
  function reservation() {
    localStorage.setItem("istakeaway", false);
    localStorage.setItem("isTable", false);
    localStorage.setItem("isreserve", true);
    window.open("/reservation", "_self");
  }

  return (
    <div className="">
      <nav
        className="navbar"
        aria-label="First navbar example"
        style={{ background: "#808080a8" }}
      >
        <div className="container-fluid cont-in">
          {/* <button className="focus" style={{ color: "grey" }} > */}
          SORDIZ
          {/* </button> */}
          <button
            className="focus"
            aria-current="page"
            style={isstable ? { color: "blue" } : { color: "grey" }}
            onClick={Table}
          >
            Tables
          </button>
          <button
            className="focus"
            aria-current="page"
            style={istakeaway ? { color: "blue" } : { color: "grey " }}
            onClick={takeaway}
          >
            Takeaway Orders
          </button>
          <button
            className="focus"
            aria-current="page"
            style={isreserve ? { color: "blue" } : { color: "grey " }}
            onClick={reservation}
          >
            Reservation
          </button>
          <div style={{ display: "flex" }}>
            {/* <div>
            <i class="fas fa-user-alt" style={{marginTop:"4px", cursor:"pointer"}} aria-hidden="true"></i>
            </div> */}
            <div class="dropdown">
              {/* <button class="btn btn-secondary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                {/* <i class="fas fa-user-alt" style={{ marginTop: "4px", cursor: "pointer" }} aria-hidden="true"></i> */}
              {/* </button> */}
              <img
                src={DP}
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {/* <img src={DP} aria-hidden="true" /> */}
              </img>
              {/* <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item" href="/productRequest">Product Request</a></li>
                <li><a class="dropdown-item" href="/support">Help/Support</a></li>
                <li><a class="dropdown-item" href="/Settings">Settings</a></li>
              </ul> */}
            </div>
            <div style={{ position: "relative", left: "4px" }}>
              <a className="nav-link active" aria-current="page">
                ADMIN
              </a>
            </div>
          </div>
          {/* <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsExample01"
            aria-controls="navbarsExample01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          {/*  */}
        </div>
      </nav>
    </div>
  );
}
