import React from "react";
import { useLocation } from "react-router-dom";
import "../Printbill/Printbillstyle.css";

export default function KitchenPrint() {
  const location = useLocation();
  const { item } = location.state;
  const print = () => {
    window.print();
  };
//   console.log("take",item);
//   const Tablenumber = apidata.tableDetails.TableNumber;
//   const orderedtime = apidata.tableProducts[0].OrderedTime;

  return (
   
    <>
      {" "}
      <div className="printcontainer">
        <p>-----------------------</p>
        <div className="headingreciept">
          <p>
            <b>Ordered Time - {item.dateCreated.slice(11, 16)} </b>
          </p>
        </div>
        <div className="orderby">
          <p>
            {" "}
            <b>Ordered By GLORIA</b>
          </p>
        </div>
        <div className="tableNumber">
          <p>
            {" "}
            <b> Order No. - {item.orderNumber} </b>
          </p>
        </div>

        {item.orderedItems.map((value) => {
          return (
            <div className="productList1">
              <div className="ptag">
                <p >
                  {value.qty}*{value.description}
                </p>
              </div>
            </div>
          );
        })}
         {/* <div className="headingreciept">
          <p>
            <b>Total Amount - {item.orderTotal} </b>
          </p>
        </div> */}
      </div>
      <div className="printBtn">
        <button class="hide-on-print" onClick={print}>
          Print
        </button>
      </div>
    </>
  );
}
