import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"; //021280
import React from "react";
import Payment from "./components/Payment";
import Tablelist from "./components/Home/tablelist";
import Homepage from "./components/Home/HomePage";
import OrderScreen from "./components/OrderScreen/orderScreen";
import Admin from "./components/AdminLogin/admin";
import ErrorPage from "./components/Error/error";
import Forgot from "./components/AdminLogin/Forgotpassword";
import TakeAway from "./components/TakeAway/takeaway";
import Reservations from "./components/Reservations/reservations";
import ProductRequest from "./components/HeaderComponents/ProductRequest";
import Others from "./components/HeaderComponents/others";
import Support from "./components/HeaderComponents/support";
import Settings from "./components/HeaderComponents/settings";
import TakeAwayList from "./components/TakeAway/TakeAwayList";
import Print from "./components/Printbill/Print";
import TakeAwayOrderScreen from "./components/TakeAway/TakeAwayOrderScreen";
import KitchenPrint from "./components/TakeAway/KitchenPrint";
import CustomerPrint from "./components/TakeAway/CustomerPrint";
import FinalReservation from "./components/Reservations/FinalReservation";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route
            path="/TableDetails/:Orderid/:tableId"
            element={<OrderScreen />}
          />
          <Route path="/Payment" element={<Payment />} />
          <Route path="/Home" element={<Tablelist />} />
          <Route path="/Homepage" element={<Homepage />} />
          <Route path="/login" element={<Admin />} />
          <Route path="/Forgotpassword" element={<Forgot />} />
          <Route path="/takeawaylist" element={<TakeAwayList />} />
          <Route path="/takeaway" element={<TakeAway />} />
          <Route path="/reservation" element={<Reservations />} />
          <Route
            path="/TakeAwayOrderScreen"
            element={<TakeAwayOrderScreen />}
          />
          <Route path="/productRequest" element={<ProductRequest />} />
          <Route path="/others" element={<Others />} />
          <Route path="/support" element={<Support />} />
          <Route path="/Settings" element={<Settings />} />
          <Route path="/Print" element={<Print />} />
          <Route path="/KitchenPrint" element={<KitchenPrint />} />
          <Route path="/CustomerPrint" element={<CustomerPrint />} />
          <Route path="/FinalReservation" element={<FinalReservation/>}/>
          <Route path="/" element={<Navigate replace to="/login" />}></Route>

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;














// fix Scanned Products Page In PAMR,
// create User Redemption Page in Pamr,
// fix css of Kitchen printing,
// test GetTakeAwayOrders api and render the list in sorted order,
// test SubmitOrder api and find the bug.
// integrate SubmitOrder Api in Sordiz app,
// integrate GetVoucherDetails api in Sordiz app.