import React from "react";
import AdminNavbar from "./AdminNavbar";
import "./Forgot.css";
import { Link } from "react-router-dom";

const Forgot = () => {
  return (
    <>
      <div className="adminNav">
        <AdminNavbar />
      </div>
      <div class="form form-1">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-md-10">
            <form class="card mt-4 card-cs">
              <div class="card-body">
                <div>
                  <h1 style={{ fontSize: "27px" }}>FORGOT PASSWORD</h1>
                </div>
                <div class="form-group">
                  <div>
                    <label for="email-for-pass">Enter your email address</label>
                  </div>
                  <div style={{ marginTop: "7px" }}>
                    <input
                      class="form-control"
                      type="text"
                      id="email-for-pass"
                      required=""
                    />
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button class="btn btn-success" type="submit">
                  Get New Password
                </button>
                <Link to="/login">
                  <button class="btn btn-danger" type="submit">
                    Back to Login
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot;
