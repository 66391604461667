let SORDIZ = {
  "BASE_URL":"https://api.sordiz.com"
  
}
export default SORDIZ





























// {
//     "BuffetItems": [],
//     "CustomerId": 0,
//     "IsSuccess": false,
//     "MobileNumber": 0,
//     "LastOrderNo": 0,
//     "PayAsYouGo": false,
//     "OrderGUID":"null",
//     "Type": "N",
//     "UserName": "Test6",
//     "UserType": "Staff",
//     "DeviceType": "WEB",
// "tableCustomer": {
//     "AdCnt": 4,
//     "CoverBuffetCnt": 0,
//     "CustomerID": 0,
//     "CustomerPoints": 0,
//     "DOB": null,
//     "DepositAmount": 0.0,
//     "DepositPaid": false,
//     "FullName": null,
//     "HighChair": 0,
//     "IncomingTime": null,
//     "JnCnt": 0,
//     "KdCnt": 0,
//     "Mobile": null,
//     "Name": null,
//     "NoOfGuests": 0,
//     "Prams": 0,
//     "PrevOrdersDate": null,
//     "Processed": false,
//     "ProductID": 0,
//     "ReservationID": 0,
//     "TableID": 0,
//     "TotalSeats": 0,
//     "Type": "N",
//     "UniqueCode": "",
//     "UserID": 0,
//     "WaitingTime": 0,
//     "WheelChair": 0,
//     "isBuffet": false,
//     "prevCust": false
// },
// "joinTables": [],
//     "tableDetails": {
//         "AdCount": 4,
//         "CurrentStatus": 0,
//         "CurrentTotal": 0,
//         "JnCount": 0,
//         "KdCount": 0,
//         "OccupiedTime": null,
//         "OrderGUID": null,
//         "ParentTableNumber": null,
//         "PaxCount": 4,
//         "RestaurantId": 3,
//         "RestaurantName": "Bolton",
//         "TableID": 224,
//         "TableNumber": "35",
//         "UniqueCode": 0
//     },
//     "tableProducts": wdata,
//        
//     "tableUser": {
//         "UserID": 208,
//         "UserLevel": 0,
//         "UserLevelID": 6,
//         "UserName": "Test6",
//         "UserPrinter": "Bar",
//         "VersionCode": 0,
//         "VersionName": null
//     }
// }


{/* <form>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputEmail4">Email</label>
      <input type="email" class="form-control" id="inputEmail4" placeholder="Email"/>
    </div>
    <div class="form-group col-md-6">
      <label for="inputPassword4">Password</label>
      <input type="password" class="form-control" id="inputPassword4" placeholder="Password"/>
    </div>
  </div>
  <div class="form-group">
    <label for="inputAddress">Address</label>
    <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St"/>
  </div>
  <div class="form-group">
    <label for="inputAddress2">Address 2</label>
    <input type="text" class="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor"/>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputCity">City</label>
      <input type="text" class="form-control" id="inputCity"/>
    </div>
    <div class="form-group col-md-4">
      <label for="inputState">State</label>
      <select id="inputState" class="form-control">
        <option selected>Choose...</option>
        <option>...</option>
      </select>
    </div>
    <div class="form-group col-md-2">
      <label for="inputZip">Zip</label>
      <input type="text" class="form-control" id="inputZip"/>
    </div>
  </div>
  <div class="form-group">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="gridCheck"/>
      <label class="form-check-label" for="gridCheck">
        Check me out
      </label>
    </div>
  </div>
  <button type="submit" class="btn btn-primary">Sign in</button>
</form> */}




















