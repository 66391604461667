import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../src/style/style.css";
const Tables = ({ tables }) => {
  const [text, setText] = useState("Joined with");

  function Guid(item) {
    console.log("done",item);
    window.sessionStorage.setItem("orderid", item.orderGUID);
  }

  return (
    <div
      style={{ backgroundColor: "#ebebde", marginTop: "-34px", height: "31.4rem" }}
    >
      <div className="container">
        <div className="last">
          <ul
            className="list-group list-group-horizontal d-inline-block"
            style={{ backgroundColor: "transparent" }}
          >
            {tables.map((item) => (
              <Link
                className="textLink"
                to={{
                  pathname: `/tableDetails/${window.sessionStorage.getItem(
                    "orderid"
                  )}/${item.tableID}`,
                  state: { tableId: item.orderGUID },
                }}
              >
                <li
                  key={item.tableID}
                  className="list-group-item text-center tableStyle"
                  onClick={() => {
                    Guid(item);
                  }}
                  //Background color change on the bases of tables's current status
                  style={{
                    backgroundColor:
                      item.currentStatus === 6
                        ? "#9B5639"
                        : item.currentStatus === 0
                        ? "#FBFFFE"
                        : item.currentStatus === 11
                        ? "#7b68ee"
                        : item.currentStatus === 1
                        ? "#DAEAF9"
                        : item.currentStatus === 2
                        ? "#DB7093"
                        : item.currentStatus === 3
                        ? "#553A76"
                        : item.currentStatus === 4
                        ? "#553A76"
                        : item.currentStatus === 5
                        ? "#FFFF00"
                        : item.currentStatus === 7
                        ? "#4A9E24"
                        : item.currentStatus === 8
                        ? "#825E2F"
                        : item.currentStatus === 9
                        ? "#757574"
                        : item.currentStatus === 10
                        ? "#228B22"
                        : item.currentStatus === 11
                        ? "#7B68EE"
                        : item.currentStatus === 12
                        ? "#FF4500"
                        : item.currentStatus === 13
                        ? "#0C0B0B"
                        : item.currentStatus === 14
                        ? "#0B0B60"
                        : "",
                    color: "black",
                    width: "30%",
                    margin: "20px 15px 20px 30px",
                    padding: "0px 5px 0px 5px",
                    cursor: "pointer",
                    border: "1px solid red",
                    float: "left",
                    listStyle: "none",
                    borderRadius: "10px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "8px",
                      float: "left",
                    }}
                  >
                    {item.currentStatus === 10
                      ? text + item.parentTableNumber
                      : ""}
                    {item.occupiedTime}
                  </span>
                  <br />
                  <span style={{ fontWeight: "bold" }}>{item.tableNumber}</span>
                  <br />
                  <span
                    style={{
                      fontSize: "12px",
                      float: "left",
                      visibility: `${
                        item.adCount === 0 && item.jnCount === 0 ? "hidden" : ""
                      }`,
                    }}
                  >
                    {item.adCount}
                    {item.jnCount}
                    {item.kdCount}
                  </span>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Tables;
